import { FC, useEffect, useState } from "react";
import { ImageDocument } from "views/Gallery/data/service";
import "./styles.css";
import { Skeleton } from "antd";
import {
  UseFetchGallery,
  UseGalleryImageDeleteMutation,
  UseGalleryInfoMutation,
} from "../../data/useGallery";
// import { useIsMobile } from "hooks/useIsMobile";
import { buildStaticURL } from "utils";
import { Link } from "react-router-dom";
import { ReactSortable, Sortable } from "react-sortablejs";

type GalleryImageProps = {
  imgData: ImageDocument;
  galleryId: string;
};

export const GalleryImageWithTools: FC<GalleryImageProps> = ({
  imgData,
  galleryId,
}) => {
  const { mutate, isPending } = UseGalleryImageDeleteMutation();
  const handleDelete = () => {
    mutate({ galleryId, imgId: imgData.id });
  };

  if (isPending) {
    return <div className="image"></div>;
  }
  return (
    // @ts-ignore
    <div
      className="image"
      style={{ backgroundImage: `url(${buildStaticURL(imgData.url.s)})` }}
    >
      <div className="image-options">
        <Link to={`/gallery/${galleryId}/image/${imgData.id}`}>
          <button className="favorite">Info</button>
        </Link>
        <button onClick={handleDelete}>Delete</button>
      </div>
    </div>
  );
};

type GalleryImagesType = {
  galleryId?: string;
};

export const GalleryItems: FC<GalleryImagesType> = ({ galleryId }) => {
  // const isMobile = useIsMobile();
  const [imageState, setImageState] = useState([]);
  const { mutate } = UseGalleryInfoMutation();
  const { data, isLoading } = UseFetchGallery(galleryId);

  useEffect(() => {
    if (data?.images) {
      // @ts-ignore
      setImageState(data?.images);
    }
  }, [data]);

  const handleChange = (
    newState: never[],
    sortable: Sortable | null,
    store: any
  ) => {
    if (
      store.dragging &&
      store.dragging.props &&
      JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState)
    ) {
      setImageState(newState);
      //@ts-ignore
      mutate({ id: galleryId, data: { images: newState } });
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <ReactSortable list={imageState} setList={handleChange} className="grid">
      {imageState.map((item: ImageDocument, index: number) => (
        // @ts-ignore
        <GalleryImageWithTools imgData={item} galleryId={galleryId} />
      ))}
    </ReactSortable>
  );
};

// }
