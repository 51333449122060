import { FC } from "react";
import {
  Form,
  Button,
  Input,
  Upload,
  message,
  Space,
  Select,
  Skeleton,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { TIMEZONES } from "constants/timezones";
import { UseFetchUser, UseUserMutation } from "hooks/useUser";
import { UserType } from "types/User";
const { Option } = Select;

export const AccountManagement: FC = () => {
  const { data, isLoading } = UseFetchUser();
  const { mutate, isPending } = UseUserMutation();

  const onFinish = (data: UserType) => {
    mutate({ data });
  };
  const onFinishFailed = (data: any) => {};

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_URL}/inventory/upload/${data?.id}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token") || null}`,
      "x-access-token": JSON.parse(sessionStorage.getItem("token") || ""),
    },
    onChange(info: any) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        // TODO replace with query
        // fetchData();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <Space direction="vertical" align="center" style={{ width: "100%" }}>
        {/* <InventoryImage
          // @ts-ignore
          {...images[id]}
          alt={data?.name}
          style={{ display: "block", margin: "0 auto" }}
        /> */}
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Space>
      <br />
      <br />
      <br />
      <Form
        name="basic"
        layout="vertical"
        initialValues={data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ maxWidth: 500, margin: "0 auto" }}
      >
        <Form.Item label="First Name" name="nameFirst">
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="nameLast"
          //   rules={[{ required: true, message: "Please Add a Name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Company Name" name="companyName">
          <Input />
        </Form.Item>
        <Form.Item label="Gallery Send URL" name="internalURL">
          <Input />
        </Form.Item>
        <Form.Item label="Website URL" name="companyURL">
          <Input addonBefore="http://" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Timezone" name="timezone">
          <Select>
            {TIMEZONES.map((tz) => (
              <Option value={tz.value}>{tz.label}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Instagram Account" name="socialIG">
          <Input />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" disabled={isPending}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
