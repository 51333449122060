import { ImageDocument } from "../data/service";

const STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const getImgURLs = (images?: ImageDocument[]) => {
  if (!images || !Array.isArray(images) || !images.length) {
    return [];
  }
  return images.slice(0, 1).map((i) => `${STATIC_STORAGE_URL}/${i.url.s}`);
};
